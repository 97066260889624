<template>
  <div class="container" ref="wrapper">
    <van-sticky>
      <van-nav-bar :title="title" left-arrow @click-left="goBack()" />
    </van-sticky>
    <van-search
      v-if="subTypeOption.length == 0"
      v-model="page.filterData.title"
      @search="onSearch"
      placeholder="请输入搜索关键词"
    />

    <van-row v-if="subTypeOption.length > 0">
      <van-col span="8">
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="page.filterData.subTypeIds"
            :options="subTypeOption"
            @change="onSearch"
          />
        </van-dropdown-menu>
      </van-col>
      <van-col span="16">
        <van-search
          v-model="page.filterData.title"
          @search="onSearch"
          placeholder="请输入搜索关键词"
        />
      </van-col>
    </van-row>

    <van-cell>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 0 ? '#9d1d22' : ''"
          @click="changeSorterType(0)"
          >默认</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 1 ? '#9d1d22' : ''"
          @click="changeSorterType(1)"
          >最新</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 3 ? '#9d1d22' : ''"
          @click="changeSorterType(3)"
          >最热</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 2 ? '#9d1d22' : ''"
          @click="changeSorterType(2)"
          >最早</van-tag
        >
      </van-col>
    </van-cell>
    <van-cell
      v-if="page.filterData.productId != '00000000-0000-0000-0000-000000000000'"
    >
      <van-col span="24">
        <van-tag
          v-if="
            page.filterData.productId != '00000000-0000-0000-0000-000000000000'
          "
          closeable
          round
          size="large"
          color="#9d1d22"
          @close="closeproduct"
        >
          {{ productName }}
        </van-tag>
      </van-col>
    </van-cell>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list
      v-model="upLoading"
      :finished="finished"
      @load="onLoad"
      finished-text="没有更多了"
    >
      <van-cell-group>
        <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
        <van-cell v-for="item in articles" :key="item.id.toString()" @click="toArticleDetail(item.id.toString())" >
          <van-col span="6"
            ><van-image
              lazy-load
              class="w100"
              fit="fill"
              style="max-height: 80px"
              :src="item.imageUrl"
              
            >
           
            </van-image>
          </van-col>
          <van-col span="18" align="bottom">
            <div
              
              style="display: table-cell; vertical-align: middle"
              class="zntitle van-multi-ellipsis--l3"
            >
              <h3 style="display: inline-block">{{ item.title }}</h3>
            </div>
            <div
              style="float: left"
              v-if="item.productName != undefined && item.productName.length > 0">
              <van-tag
                @click.stop="toProduct(item)"
                size="large"
                color="rgb(255 242 219)"
                text-color="#9d1d22"
                >{{ item.productName }}
              </van-tag>
            </div>
            <div style="float: right">{{ item.creationTime }}</div>
            <div style="float: right; margin-right: 13px">
              浏览{{ item.readCount }}人次
            </div>
          </van-col>
        </van-cell>
      </van-cell-group>
    </van-list>
      </van-pull-refresh>
  </div>
</template>

<script>
import { getArticleList, getArticleTypeList,getArticleTypeItem } from "@/api/article";
import { getArticleType } from "@/utils/datetype";
import { getProductName } from "@/api/product";
//const key = "zn-history";
export default {
  name: "znarticle",
  data() {
    return {
      scroll: 0,
      refreshing: false,
      productName: "",
      subTypeOption: [],
      type: 100,
      title: "",
      keyword: "",
      upLoading: false,
      finished: false,
      articles: [],
      page: {
        current: 1,
        pageSize: 10,
        sorterType: 0,
        filterData: {
          subTypeIds: "",
          title: "",
          typeId: 100,
          productId: "00000000-0000-0000-0000-000000000000",
        },
      },
      isWeixin: navigator.userAgent.toLowerCase().indexOf("micromessenger") > -1,
      isMiniprogram:false,
    };
  },
  components: {},
  activated() {
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.initData();
  },
  mounted() {},

  methods: {

    goBack(){
      if(this.$route.query.back){
        this.$router.back();
      }
      else{
        this.$router.replace({ path: '/'})
      }
    },
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    async initData() {
      this.page.sorterType = 0;
      let type = this.$route.query.type;
      this.type = type;
      if(this.type==106){
        this.page.sorterType=1;
      }
      this.page.filterData.typeId = type;

if(this.isWeixin){
  
  try {
    
  
      //var jsConfig=await GetAppJsConfig(encodeURIComponent(location.href.split("#")[0]));

      let win = window;
      if (win.wx) {
        // win.wx.config({
        //   debug: false,
        //   appId: jsConfig.appId,
        //   timestamp: jsConfig.timestamp,
        //   nonceStr: jsConfig.nonceStr,
        //   signature: jsConfig.signature,
        //   jsApiList: [
        //     "checkJsApi",
            
        //   ],
        // });
        win.wx.ready(() => {
          console.log("微信jssdk加载成功!!!!")
        });
        win.wx.error((res) => {
          console.log("微信jssdk加载失败!!!"+res.errMsg);
        });


        win.wx.miniProgram.getEnv((res) => {
          console.log(res);
            if (res.miniprogram) {
              console.log("在小程序里！！！")
            } else {
              console.log("不在小程序！！！")
            }
        })
      }
      } catch (error) {
     console.log(error)
  }


}
else{
   console.log("不在微信中")
}


      
      
      this.title = getArticleType(type);
      if(this.title=='')
      {
        let articleType= await getArticleTypeItem(type);
        this.title=articleType.data.data.typeName;
      }

      document.title=this.title;
     

      let productId = this.$route.query.productId;
      if (productId === undefined) {
        productId = "00000000-0000-0000-0000-000000000000";
      }
      this.page.filterData.productId = productId;

      if (productId != "00000000-0000-0000-0000-000000000000") {
        let nameReuslt = await getProductName(productId);
        this.productName = nameReuslt.data.data;
      }

      let subTypeList = await getArticleTypeList({
        current: 1,
        pageSize: 100,
        filterData: {
          parentID: this.type,
        },
      });
      if (subTypeList.data.data.length > 0) {
        this.subTypeOption = [{ text: "全部", value: "" }];
        subTypeList.data.data.map((x) =>
          this.subTypeOption.push({ text: x.typeName, value: x.id.toString() })
        );
      }
    },
    toProduct(item) {
      if (this.page.filterData.productId != item.productId) {
        this.productName = item.productName;
        this.page.filterData.productId = item.productId;
        this.onSearch();
      }
    },
    closeproduct() {
      this.page.filterData.productId = "00000000-0000-0000-0000-000000000000";
      this.onSearch();
    },
    onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    toArticleDetail(id) {

      let wrapperScrollTop = this.$refs.wrapper.scrollTop;
        //存储 scrollTop 的值
      this.scroll = wrapperScrollTop;

      this.$router.push({
        path: "/article/detail",
        query: { para: id, type: this.page.filterData.typeId },
      });
    },
    async onLoad() {
      this.upLoading = true;
      console.log("page",this.page);
      let aresult = await getArticleList({ ...this.page }); //({ ...this.page })
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
    changeSorterType(typeid) {
      if (this.page.sorterType != typeid) {
        this.page.sorterType = typeid;
        this.onSearch();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 80px;
  }
}
.zntitle {
  height: 80px;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
